import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contato")}</Language>
              <Para>
                {t(`Você tem alguma pergunta? Sinta-se à vontade para entrar em contato.`)}
              </Para>
              <a href="mailto:equitys.tecnologia@outlook.com">
                <Chat>{t(`Vamos conversar`)}</Chat>
              </a>
            </Col>

            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Empresa")}</Title>
              <Large to="/">{t("Sobre")}</Large>
              <Large to="/">{t("Blog")}</Large>
            </Col>

            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large to="/">{t("Central de suporte")}</Large>
              <Large to="/">{t("Customer Support")}</Large>
            </Col> */}
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Endereço")}</Language>
              <Para>Av.Federal, 178 </Para>
              <Para>Quadra 09 | Lote 15</Para>
              <Para>Vila Santana</Para>
              <Para></Para>
            </Col>
         
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> */}
          </Row>
        </Container>
      </FooterSection>
        <Extra>
          <Container border={true}>
            
              <Row
                justify="space-between"
                align="middle"
                style={{ paddingTop: "3rem" }}
              >
                

                <NavLink to="/">
                  <LogoContainer>
                    <SvgIcon
                      src="logoIcon.png"
                      aria-label="homepage"
                      width="64px"
                      height="64px"
                    />
                  </LogoContainer>
                </NavLink>
                <Col lg={10} md={10} sm={12} xs={12}>
                  <FooterContainer>
                    <SocialLink
                      href="https://github.com/equitystecnologia"
                      src="github.svg"
                    />
                    <SocialLink
                      href="mailto:equitys.tecnologia@outlook.com"
                      src="email.svg"
                    />
                    <SocialLink
                      href="https://www.linkedin.com/in/pedrohvs/"
                      src="linkedin.svg"
                    />
                    <SocialLink
                      href="www.google.com"
                      src="instagram.svg"
                    /> 

                  </FooterContainer>
                </Col>
              </Row>
          </Container>
        </Extra>
    </>
  );
};

export default withTranslation()(Footer);
