import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  DivMobile,
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("Sobre")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("o que fazemos")}>
          <Span>{t("Produto")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("vantagens equitys")}>
          <Span>{t("Vantagem")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contato")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
      
        <Row justify="space-between" >
        
        
          <LogoContainer to="/" aria-label="homepage">
          <DivMobile>
          <SvgIcon src="logoIcon.svg" width="64px" height="64px" />
          </DivMobile>
          
             <h1 style={{  margin:'0px', alignSelf: 'center', fontSize: '1.5rem' }}>{("Equitys tecnologia")}</h1>
            
          </LogoContainer>
          <NotHidden>
            <MenuItem />
            
          </NotHidden>
          
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
          
        </Row>
        <Row style={{position:"relative", right:"0"}}>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
